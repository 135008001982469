<script lang="ts">
  import toast_, { type Toast } from '../toasts';

  export let toast: Toast;

  // Use this component in your app:
  // toast(RichContent)

  const { message, action } = (toast.props || {}) as {
    message: string;
    action: { label: string; callback: () => void };
  };
  if (!message || !action) {
    throw new Error('ActionToast component requires a toast with props.message and props.action');
  }
</script>

<span class="tw-gap-y-1">
  <span class="tw-block tw-break-words tw-overflow-hidden">{message}</span>
  <button
    class="tw-bg-transparent tw-flex tw-border-0 tw-text-left tw-text-blue-500 tw-p-0 tw-m-0 hover:tw-underline"
    on:click={() => {
      action.callback();
      toast_.dismiss(toast.id);
    }}
  >
    {action.label}
  </button>
</span>
